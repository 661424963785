import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/slipgripsBrandHeader"
import BrandBody from "../../components/brand-bodies/slipgripsBrandBody"

const SlipgripsPage = ({ pageContext, location }) => {
  const pageTitle = "Slipgrips"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        SlipGrips offers highly rated slip-resistant stylish footwear designed
        for comfort and performance in the food service, hospitality,
        healthcare, warehouse and distribution industries. Exclusive SpiderGrip
        and DragonGrip tread designs exceed ASTM test standards. Don't Slip, Get
        Gripped.{" "}
      </p>
    </>
  )
}

export default SlipgripsPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">SlipGrips</title>
    <meta
      name="description"
      content="SlipGrips offers highly rated slip-resistant stylish footwear designed for comfort and performance in the food service, hospitality, healthcare, warehouse and distribution industries. Exclusive SpiderGrip and DragonGrip tread designs exceed ASTM test standards. Don't Slip, Get Gripped."
    />
  </>
)
