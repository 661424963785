import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About SlipGrips</h2>
          <p>SlipGrips offers highly rated slip-resistant stylish footwear designed for comfort and performance in the food service, hospitality, healthcare, warehouse and distribution industries. Exclusive SpiderGrip and DragonGrip tread designs exceed ASTM test standards. Don't Slip, Get Gripped.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-headers/rocky/factory-sepia.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      {/* <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-outdoor-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky outdoor"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-work-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky work"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-western-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky western"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-psd-commil-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky public service and commercial military"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Our High STANDard</h4>
            <p>SlipGrips requires all styles on its website to be independently lab tested and achieve a Mark II ASTM F1677 result of .45 or higher on oily/wet.</p>
            <h4>DragonGrip Tread Technology</h4>
            <p>This SlipGrips propriety tread consists of hundreds of hexagonal pylons of varying heights that form a 3-dimensional pattern. On a micro level, each individual 6-sided pylon independently adjusts to changing surface textures while creating numerous small channels that efficiently funnel away liquid, soap and grease. At the same time on a macro level, the larger valleys created by the 3D relief allow for faster dispersion and therefore greater outsole-to-floor contact to prevent hydroplaning. Independent laboratory Mark II ASTM F1677-05 test results confirm unrivaled with superior test scores of .62 in oily and wet and .54 in soapy conditions.</p>
          </div>
      </div> */}
      <div className={brandBodyStyles.strategy}>
        <h4>SlipGrips Strategy</h4>
        <p>SlipGrips primary focus is supplying the best selection of highly rated Slip-Resistant comfort footwear to workers in Hospitality, Healthcare, Food Service, Maintenance and Warehouse Distribution who need to feel safe and secure on the job without worry of a slip or fall accident. All SlipGrips branded styles are exclusively sold off of our retail website and through our managed footwear programs Lehigh CustomFit and Shoe Angel.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
